.module__popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 100%;
  max-width: 1200px;
  // min-height: 600px;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  transition: all .3s;
  background-color: var(--body-color);

  &__close {
    position: absolute;
    top: 0;
    right: 10px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: var(--title-color);

    &:hover {
      cursor: pointer;
      opacity: .7;
    }
  }

  &__content {
    max-height: 30%;
    overflow: auto;
  }

}

@media screen and (max-width: 700px) {
  .module__popup {
    max-width: calc(100% - 32px);
  }
}